import React from 'react'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import { Container, PagePadding, Grid, defaultTheme, Table } from '../styles'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SectionHeading from '../components/SectionHeading'

const ResourcesPage = () => {
    const hotlines = [
        {
            name: "Rape, Abuse, and Incest National Network (RAINN) – National Sexual Assault Hotline",
            number: "1 (800) 656-4673",
        },
        {
            name: "Department of Defense (DOD) Safe Helpline for Sexual Assault",
            number: "1 (877) 995–5247",
        },
        {
            name: "National Sexual Assault Hotline",
            number: "1 (800) 656-4673",
        },
        {
            name: "Anti-Violence Project",
            number: "1 (212) 714-1141",
        },
    ]
    return (
        <Layout>
            <PageBanner title="Resources" subtitle="Child Sex Abuse Law Firm" />
            <Container>
                <PagePadding>
                    <Table>
                        <thead>
                            <tr>
                                <th colspan={`2`}>
                                    Hotlines For Sexual Abuse & Assault Concerns
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hotlines.map(hotline => (
                                <tr key={hotline.name}>
                                    <td>{hotline.name}</td>
                                    <td>{hotline.number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {/* <Grid twoCol>
                        <Block to="/reviews/">
                            Reviews
                        </Block>
                    </Grid> */}
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default ResourcesPage

const Block = styled(Link)`
    padding: 104px 24px;
    background: ${defaultTheme.darkBlue};
    color: white;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.063em;
    text-align: center;
    transition: linear 0.35s;

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 0.9;
        }
    }
`;
