import React from 'react'
import { Container } from '../styles'
import styled, {css} from 'styled-components'
import { defaultTheme } from '../styles'

const SectionHeading = ({heading, color}) => {
    return (
        <Container flex justifyCenter alignCenter>
            <SectionHeadingWrapper>
                <h2 className={`${color} h2`}>{ heading }</h2>
            </SectionHeadingWrapper>
        </Container>
    )
}

export default SectionHeading

const SectionHeadingWrapper = styled.div`
    padding: 40px 0;
    text-align: center;

    @media(min-width: 900px) {
        max-width: 700px;
        padding: 56px 0;
    }

    @media(min-width: 1200px) {
        /* max-width: 75%; */
        padding: 72px 0;
    }

    ${props => props.blue && css`
        color: ${defaultTheme.darkBlue}
    `}
`;


